import React from 'react'
import './pricing.css'
import { GiConsoleController } from "react-icons/gi";



function Pricing(){
    return (
        <div className='pricing-container'>
            <h1 className='h1-website'>Website</h1>
            <div className='card-container'>
            <div className='left-card1'></div>
            <div className='left-card2'></div>
            <div className='left-glass'>
                <h1 className='h1-text'>BUNDLE 01</h1>
                <h2 className='h2-text'>Basic</h2>
                <h4>Responsive in all devices</h4>
                <h4>Basic layout</h4>
                <h4>Basic Animations and  effects</h4>
                <h4>Unique Design as per your product/service</h4>
                <h2 className='h2-text'>Working on updates, Check back later!</h2>
            </div>
            <div className='mid-card1'></div>
            <div className='mid-card2'></div>
            <div className='mid-card3'></div>
            <div className='mid-glass'>
            <h1 className='h1-text'>BUNDLE 10</h1>
                <h2 className='h2-text'>Standard</h2>
                <h4>Responsive in all devices</h4>
                <h4>Dynamic layout</h4>
                <h4>Standard Animations and  effects</h4>
                <h4>Unique Design as per your product/service</h4>
                <h2 className='h2-text'>Working on updates, Check back later!</h2>
            </div>
            <div className='right-card1'></div>
            <div className='right-card2'></div>
            <div className='right-glass'>
            <h1 className='h1-text'>BUNDLE 100</h1>
                <h2 className='h2-text'>Advanced</h2>
                <h4>Responsive in all devices</h4>
                <h4>Highly dynamic layout</h4>
                <h4>3D Animations and  effects</h4>
                <h4>Unique Design as per your product/service</h4>
                <h2 className='h2-text'>Working on updates, Check back later!</h2>
            </div>
             </div>
        <div className='app-container'>
        <h1 className='h1-app'>App</h1>
            <div className='circle1'></div>
            <div className='circle2'></div>
            <div className='circle-glass'></div>
            <div className='app-text-container'>
            <h1 className='h1-text'>BUNDLE A</h1>
            <h4>Compatible in playStore and ios AppStore</h4>
            <h4>Dynamic Design</h4>
            <h4>Unique Design as per your product/service</h4>
            <h4>App maintenance and support </h4>
            <h2 className='h2-text'>Working on updates, Check back later!</h2>
            </div>
            <div className='game-glass'></div>
        <h1 className='h1-game'>Games</h1>
        <div className='game-text-container'>
            <h1 className='h1-text'>BUNDLE G</h1>
            <h4>High-end design and conceptualization</h4>
            <h4>Top-Notch Animations and effects</h4>
            <h4>Compatible in playStore,ios AppStore and web</h4>
            <h4>App maintenance and support </h4>
            <h2 className='h2-text'>₹ Connect with us</h2>
            <GiConsoleController className="game-icon" size={190} color='aqua' style={{position: 'absolute',top: '350px', right: '10px'}} />
            </div>
            
            
        </div>
            
        </div>
    )
}

export default Pricing;