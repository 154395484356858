import { Link } from 'react-router-dom';
import './navbar.css'

function Navbar(){
    return (
        <div>
            <nav className='nav-container'>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/howwework">How we work?</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li>
          <Link to="/pricing">Pricing</Link>
        </li>
        <li>
          <Link to="/connect">Connect</Link>
        </li>
      </ul>
    </nav>
        </div>
    )
}

export default Navbar;