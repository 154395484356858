import React from 'react';
import './about.css'
import { CiCoffeeCup } from "react-icons/ci";

function About(){
    return (
        <div className='about-container'>
            <h1 className='about-process'>Our Process</h1>
             <p className='p1'>Send us a request and tell us your <br />requirements, our team will send you < br />a price quote.</p>
             <p className='p2'>Once your requirements meet, <br /> you will get notified for your order via <br />whatsapp/e-mail. </p>
             <p className='p3'>Now schedule a video call with our finest <br />developer,and share us your full details, <br />even if you have your own idea <br />you can share with us as well!</p>
             <p className='p4'>Sit back &nbsp;and &nbsp;&nbsp;&nbsp;&nbsp; sip a coffee! <CiCoffeeCup size={40} color='yellow' style={{position:'relative',top: ' 10px',rotate: '15deg'}}/> Crafting...</p>
             <p className='p5'>Within few working days, we'll craft < br/> and host your website in our server,<br /> so that you will be reviewing it as <br />a live demo at your end!</p>
             <p className='p6'>In review 1, if you had any corrections/ < br/>modifications, all changes will be made <br />and sent to you once again.</p>
             <p className='p7'>In final review, before going live <br />you can check the complete product <br />in our server and discuss with us <br />for domain and viola moving next!</p>
             <p className='p8'>Boom! Going Live!</p>
            <div className='line-container'>
                <div className='bottom-line'></div>
                <div className='top-line'></div>
            </div>
            <div className='glitch-container'>
            <div className='card1' >
            <h5 className='glitch1' data-text="Get a Quote">Get a quote</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card2' >
            <h5 className='glitch2' data-text="Order confirmation">Order confirmation</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card3' >
            <h5 className='glitch3' data-text="Full details given">Full details given</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card4' >
            <h5 className='glitch4' data-text="Review 1">Review 1</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card5' >
            <h5 className='glitch5' data-text="Review 2">Review 2</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card6' >
            <h5 className='glitch6' data-text="Final review">Final review</h5>
            </div>
            </div>
            <div className='glitch-container'>
            <div className='card7' >
            <h5 className='glitch7' data-text="Website/App Launch">Website/App Launch</h5>
            </div>
            </div>
            <div className='text-about'>
                <h2>Vision</h2>
                <p>We are on a journey to bring any kind of website /mobile apps more personalized and customized. Let it be a business related product/service, we make a memorable experience for the user to interact with your product/service. Making it peculiar, provides high user engagement. We want each and every user have an amazing experience when they visit your website/mobile apps.</p>
                <h2>Mission</h2>
                <p>We understand that in this fast paced world every business needs a website or an app and we are here to bring that service online so that you can focus more on other tasks. Our process is completely online so no need for in-person visits. All websites /apps/games will be delivered online for our customer's convenience. </p>
            </div>
        </div>
    )
}

export default About;