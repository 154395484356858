import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home'
import About from './About'
import Navbar from './Navbar';
import Logo from './Logo';
import Pricing from './Pricing';
import Service from './Service';
import Portfolio from './Portfolio';
import Contact from './Contact';

const App = () => {
  return (
    
    <Router>
      <Logo />
       <Navbar />
      <div>
        <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/howwework" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Service />} />
        <Route path="/connect" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;