import React from 'react'
import './portfolio.css'


function Portfolio(){
    return (
        <div className='portfolio-container'>
             <h2 className='image-h2'>Prototype Visuals</h2>
            <div className='image-container'>
               <img src="image12.jpg" alt='coffee1' />
               <img src="image13.jpg" alt='coffee2' />
               <img src="image14.jpg" alt='coffee3' />
               <img src="image10.jpg" alt='music' />
               <img src="image11.jpg" alt='music2' />
               <img src="image1.jpg" alt='forest' />
               <img src="image2.jpg" alt='forest2' />
               <img src="image3.jpg" alt='food' />
               <img src="image4.jpg" alt='food2' />
               <img src="image5.jpg" alt='gallery' />
               <img src="image6.jpg" alt='gallery2' />
               <img src="image7.jpg" alt='travel' />
               <img src="image8.jpg" alt='travel2' />
               <img src="image9.jpg" alt='pet' />
            </div>
            <h2 className='image-h2'>Layout Clips</h2>
            <div className='video-container'>
            <iframe className='video' width="560" height="315" src="https://www.youtube.com/embed/73lHMtELtCU?si=yr2eR00mF2Pb-gUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>    
            <iframe className='video' width="560" height="315" src="https://www.youtube.com/embed/4L7T9mkU6_Q?si=JZldhNYVhlcL5UPp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe className='video' width="782" height="440" src="https://www.youtube.com/embed/0KGm_Hq8G4k" title="Site Encoders - Website design and development - Restaurant /bakery model" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe className='video' width="704" height="440" src="https://www.youtube.com/embed/BUQAuKw281w" title="Site encoders - Website design and development - 3D Gallery website model" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe className='video' width="782" height="440" src="https://www.youtube.com/embed/0i8b-OEz5hE" title="Site Encoders - Website design and development - Hiking website layout" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe className='video' width="782" height="440" src="https://www.youtube.com/embed/qLSkxwH4s6o" title="Site Encoders - Website Layout - Travel" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe className='video' width="782" height="440" src="https://www.youtube.com/embed/qeL2lXJjJ2E" title="Site Encoders - website design layout- Pet store" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
           </div>
            <div className='demo-container'>
                <h1 className='image-h2demo'>3D Live Demo!</h1>
                <h3 className='image-h2demo'>Launching Soon...</h3>
            </div>
        </div>
    )
}

export default Portfolio;