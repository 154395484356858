
import React from 'react';
import './home.css';
import {Link} from 'react-router-dom'


function Home(){
    return (
        
    <div className='home-container'>
            <div className='main-container'>
            <div className='center-screen-middle'>
                <div className='m-blink-1'>&lt;Creative /&gt;</div>
                <div className='m-blink-2'>&lt;App /&gt;</div>
                <div className='m-blink-3'>&lt;bot Assistance /&gt;</div>
                <div className='m-blink-4'>&lt;#@&| /&gt;</div>
                <div className='code-text'><h3 className='h3-text'>Unique Design</h3></div>
                <h3 className='main-text-middle'>Next-Gen</h3>
            </div>
            <div className='center-screen-right'>
                <div className='code-text'><h3 className='h3-text'>Customized</h3></div>
                <h3 className='main-text-right'>Website/App?</h3>
                <div className='r-blink-1'>&lt;Algorithms /&gt;</div>
                <div className='r-blink-2'>&lt;3D effects /&gt;</div>
                <div className='r-blink-3'>&lt;Games /&gt;</div>
                <div className='r-blink-4'>&lt;dev /&gt;</div>
            </div>
            <div className='center-screen-left'>
                <div className='code-text'><h3 className='h3-text'>Responsive</h3></div>
                <h3 className='main-text-left'>Want a</h3>
                <div className='l-blink-1'>&lt;www /&gt;</div>
                <div className='l-blink-2'>&lt;Link /&gt;</div>
                <div className='l-blink-3'>&lt;design /&gt;</div>
                <div className='l-blink-4'>&lt;Animations /&gt;</div>
            </div>
            <div className='button-container'>
            <Link to="/connect">
            <button className='home-button'>IF YES?</button>
            </Link>
            </div>
            </div> 
        </div>
    )
}


export default Home;