import React from 'react';
import './service.css'


function Service(){
    return (
        <div>
        <div className='service-conatiner'>
            <div className='s-circle1'>
                <h3 className='h3-cv'>No Templates</h3>
            </div>
            <div className='s-circle2'>
            <h3 className='h3-c'>Not from website builders</h3>
            </div>
            <div className='s-circle3'>
            <h3 className='h3-c'>Highly Dynamic</h3>
            </div>
            <div className='s-circle4'>
            <h3 className='h3-cvm'>Fully customized</h3>
            </div>
            <div className='s-circle5'>
            <h3 className='h3-c'>No physical visit</h3>
            </div>
            <div className='s-circle6'>
            <h3 className='h3-c'>Highly interactive</h3>
            </div>
            <div className='s-circle7'>
            <h3 className='h3-c'>High user engagement</h3>
            </div>
            <div className='s-circle8'>
            <h3 className='h3-cv'>Attractive design</h3>
            </div>
        </div>
        <div className='service-text'>
            <h1 className='service-heading'>Our Services</h1>
            <h2 className='service-heading'>Website</h2>
            <p>We craft a personalized and customized website for your business depending upon your product/service.
               We design your website with a new look which would totally be unique. Even if you have some ideas related to design for your website
               we can collaborate together and bring your ideas or imaginations to life! Your product is unique so why cant your website have a unique look!
            </p>
            <h2 className='service-heading'>Apps</h2>
            <p>Same as website, every app deserves to be unique and customized as per the customer's choice. Based on your product/service we can bring upon
                a new idea or if you have an idea collaborate with us and let's bring those designs and ideas to life! 
            </p>
            <h2 className='service-heading'>Games</h2>
            <p>Bring your ideas and we just make your dream game come true! Every individual has some cool ideas flowing in their mind we craft it and make it real! For more details contact us directly!</p>
            <h2 className='service-heading'>Personal Website Portfolio</h2>
            <p>Need an online presence just for you? Based on your interests and characteristics your website will be crafted, so that your portfolio talks to others rather than a boring content!</p>
            <h2 className='service-heading'>Animated Logos</h2>
            <p>Tired of same old image based logos, we craft logos that are animated and has some cool effects! This literally adds some quirky touch to your website/app</p>
            <h2 className='service-heading'>Chat Assistant/ Chatbots</h2>
            <p>Need an personal assistant for your website/app, responds to most of your queries related to your products/services. Highly customized for handling your business related queries.</p>
        </div>
        
        </div>
    )
}

export default Service;