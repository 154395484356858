import React from 'react';
import { IoTriangleOutline } from "react-icons/io5";
import { IoSquareOutline } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import './logo.css'

function Logo(){
    return (
        <div className='logo-container'>
            <div className='logo-bf'> &lt; </div>
            <div className='logo-wf'> SiTe en </div>
            <div className='logo-r'> CODE </div>
            <div className='logo-we'> rs </div>
            <div className='logo-be'> /&gt; </div>
            <IoTriangleOutline color='blue' size={20} className="triangle" style={{position: 'absolute', top: '45px', left: '150px'}}/>
            <IoSquareOutline color='blue' size={20} className="square" style={{position: 'absolute', top: '45px', left: '170px'}}/>
            <LuDot color='white' size={30} className="dot1" style={{position: 'absolute', top: '45px', left: '185px'}}/>
            <LuDot color='white' size={30} className="dot2" style={{position: 'absolute', top: '45px', left: '195px'}}/>
            <LuDot color='white' size={30} className="dot3" style={{position: 'absolute', top: '45px', left: '205px'}}/>
        </div>
    )
}

export default Logo;