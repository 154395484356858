import React from 'react';
import './contact.css';
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";

const Contact = () => {


  return (
    <div>
    <div className='container'>
      <iframe className="form" src="https://docs.google.com/forms/d/e/1FAIpQLSc44kuRWWFwY34oK6nJQ1_fM07NvGUC9AmIvld9Nua8IyDhtQ/viewform?embedded=true" title="form" width="640" height="1715" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
      <div classname="icon-container">
      <a href='whatsapp://send?phone=9361735172' target='_blank' rel="noreferrer"><FaWhatsapp className='whatsapp' size={25} color='white' style={{position: 'absolute', top: '120px',left: '20px'}}/></a>
        <a href='https://www.facebook.com/profile.php?id=61555959687792' target='_blank' rel="noreferrer"><FaFacebook className='fb' size={25} color='white'style={{position: 'absolute', top: '160px',left: '20px'}}/></a>
        <a href='https://www.linkedin.com/company/site-encoders/' target='_blank' rel="noreferrer"><FaLinkedin className='linkedin' size={25} color='white'style={{position: 'absolute', top: '200px',left: '20px'}}/></a>
        <a href='https://youtube.com/@SiteEncoders?si=UEoQqRVV2YwPRhUs' target='_blank' rel="noreferrer"><FaYoutube className='youtube' size={25} color='white'style={{position: 'absolute', top: '240px',left: '20px'}}/></a>
        <a href=' https://twitter.com/siteencoders' target='_blank' rel="noreferrer"><FaXTwitter className='x' size={25} color='white' style={{position: 'absolute', top: '280px',left: '20px'}}/></a>
        <a href='https://www.instagram.com/site_encoders/' target='_blank' rel="noreferrer"><FaInstagram className='insta' size={25} color='white'style={{position: 'absolute', top: '320px',left: '20px'}}/></a>
      </div>
      <footer className='footer'>
        <p>Copyright &copy; 2024 site encoders. All rights reserved.</p>
        <p><IoMdCall size={20} color='black'/>+91 93617 35172 <IoMdMail size={20} color='black'/>contact@siteencoders.in</p>
      </footer>
      </div>
  );
};

export default Contact;
